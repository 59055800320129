import { mapActions, mapMutations } from 'vuex';
import ProductCard from '@/components/cards/ProductCard/index.vue';

export default {
  name: 'search-catalog',
  components: {
    ProductCard
  },
  data() {
    return {
      querySearch: '',
      instagramPosts: [],
      searchLoading: false,
      searchResultData: { news: [], products: [] }
    };
  },
  async created() {
    this.querySearch = this.$route.query.search;

    await Promise.all([
      this.getInstagramPosts().then((instagramPosts) => (this.instagramPosts = instagramPosts)),
      this.fetchResults()
    ]);
  },
  computed: {},
  methods: {
    ...mapActions({
      fetchSearchItems: 'search/GET_SEARCH_LIST',
      getInstagramPosts: 'home/INSTAGRAM_POSTS'
    }),
    ...mapMutations({
      changeInstagramPopup: 'popups/CHANGE_INSTAGRAM_POPUP'
    }),
    async fetchResults() {
      this.searchLoading = true;
      this.searchResultData = await this.fetchSearchItems(this.querySearch);
      this.searchLoading = false;
    },
    sendRequest: _.debounce(function () {
      if (this.querySearch.length > 2) {
        this.fetchResults();
      }
    }, 500),
    changeSearch() {
      this.sendRequest();
    }
  }
};
